<template>
  <div style="width: 100%;">

    <div class="row row-sm mg-t-20">
      <div class="col-6">
          <div class="spinner-container">
            <h2 style="color: white; text-decoration: underline;">
              PENDING CLAIMS - OPENED ({{ pending.opened }})
              <div v-if="loading" class="spinner"></div>
            </h2>
          </div>
      </div>
      <div class="col-6">
        <h2>
          <input type="date" v-if="$can('claims-manage-team')" class="form-control-dark form-control" v-model="date" @change="getData" style="float: right; width: 150px;">
        </h2>
      </div>
    </div>

    <div class="row row-sm mg-t-20">

      <!-- PROCESSED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">PROCESSED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{pending.processed.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

      <!-- CANCELLED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">CANCELLED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{pending.cancelled.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

      <!-- LOCKED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">LOCKED / REMINDER</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{pending.locked.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

    </div>

    <div class="row row-sm mg-t-20">

      <!-- TAMPERED PLATE -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">TAMPERED PLATE</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{pending.tampered.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

      <!-- AWAITING EVIDENCE -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">AWAITING EVIDENCE</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{pending.awaitingEvidence.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

      <!-- CONTACTED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">SITES CONTACTED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{pending.contacted.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

    </div>

    <div class="row row-sm mg-t-20">
      <div class="col">
        <div class="spinner-container">
          <h2 style="color: white; text-decoration: underline;">
            DVLA REVIEW - OPENED ({{ dvlaReview.opened }})
            <div v-if="loading" class="spinner"></div>
          </h2>
        </div>
      </div>
    </div>

    <div class="row row-sm mg-t-20">

      <!-- PROCESSED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">PROCESSED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{dvlaReview.processed.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

      <!-- CANCELLED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">CANCELLED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{dvlaReview.cancelled.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

    </div>

    <div class="row row-sm mg-t-20">
      <div class="col">
          <div class="spinner-container">
            <h2 style="color: white; text-decoration: underline;">
              DEBT COLLECTION REVIEW - OPENED ({{ debtCollectionReview.opened }})
              <div v-if="loading" class="spinner"></div>
            </h2>
          </div>
      </div>
    </div>

    <div class="row row-sm mg-t-20">

      <!-- PROCESSED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">PROCESSED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{debtCollectionReview.processed.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

      <!-- CANCELLED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">CANCELLED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{debtCollectionReview.cancelled.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

    </div>

    <div class="row row-sm mg-t-20">
      <div class="col">
          <div class="spinner-container">
            <h2 style="color: white; text-decoration: underline;">
              SCANNED DOCUMENTS - VIEWED ({{ scannedDocuments.viewed }})
              <div v-if="loading" class="spinner"></div>
            </h2>
          </div>
      </div>
    </div>

    <div class="row row-sm mg-t-20">

      <!-- PROCESSED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">PROCESSED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{scannedDocuments.processed.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

      <!-- RESTRICTED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">RESTRICTED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{scannedDocuments.restricted.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

      <!-- DELETED -->
      <div class="col-sm-6 col-lg-4">
        <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
            <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">DELETED</h6>
            </div>
            <div class="card-body d-xs-flex justify-content-between align-items-center">
                <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{scannedDocuments.deleted.toLocaleString()}}</h4>
            </div>
        </div>
      </div>

    </div>

  </div>  
</template>

<script>
import axios from "axios";
import authHeader from "@/services/auth-header";
import { DateTime } from "luxon";

export default {
  name: 'Claims',
  props: {
    user_id: {
      type: Number,
      required: false
    }
  },
  data(){
    return {
      pending: {
        opened: 0,
        processed: 0,
        cancelled: 0,
        locked: 0,
        tampered: 0,
        awaitingEvidence: 0,
        contacted: 0
      },
      dvlaReview: {
        opened: 0,
        processed: 0,
        cancelled: 0
      },
      debtCollectionReview: {
        opened: 0,
        processed: 0,
        cancelled: 0
      },
      scannedDocuments: {
        viewed: 0,
        processed: 0,
        restricted: 0,
        deleted: 0
      },
      counter: 0,
      date: DateTime.local().toFormat('yyyy-MM-dd'),
      loading: false
    }
  },
  mounted(){
    this.getData();
  },
  methods: {
    getData(){ 
      this.loading = true;
      this.counter++;
      let currentCount = this.counter;
      axios.get(`https://api.varsanpr.com/api/dashboard/claims`, {
        headers: authHeader(),
        params: {
          user_id: this.user_id || null,
          date: this.date
        }
      })
      .then(response => {
        if(currentCount !== this.counter) return;
        this.pending.opened = response.data.stats.pendingOpened;
        this.pending.processed = response.data.stats.pendingProcessed;
        this.pending.cancelled = response.data.stats.pendingCancelled;
        this.pending.locked = response.data.stats.pendingLocked;
        this.pending.tampered = response.data.stats.pendingTamperedPlate;
        this.pending.awaitingEvidence = response.data.stats.pendingAwaitingEvidence;
        this.pending.contacted = response.data.stats.pendingSitesContacted;
        this.dvlaReview.opened = response.data.stats.dvlaReviewOpened;
        this.dvlaReview.processed = response.data.stats.dvlaReviewProcessed;
        this.dvlaReview.cancelled = response.data.stats.dvlaReviewCancelled;
        this.debtCollectionReview.opened = response.data.stats.debtCollectionReviewOpened;
        this.debtCollectionReview.processed = response.data.stats.debtCollectionReviewProcessed;
        this.debtCollectionReview.cancelled = response.data.stats.debtCollectionReviewCancelled;
        this.scannedDocuments.viewed = response.data.stats.scannedDocumentsViews;
        this.scannedDocuments.processed = response.data.stats.scannedDocumentsProcessed;
        this.scannedDocuments.restricted = response.data.stats.scannedDocumentsRestricted;
        this.scannedDocuments.deleted = response.data.stats.scannedDocumentsDeleted;

      })
      .catch(e => {
        console.log(e);
        this.$error('Failed to load claims stats');
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    user_id(){
      this.pending.opened = 0;
      this.pending.processed = 0;
      this.pending.cancelled = 0;
      this.pending.locked = 0;
      this.pending.tampered = 0;
      this.pending.awaitingEvidence = 0;
      this.pending.contacted = 0;
      this.dvlaReview.opened = 0;
      this.dvlaReview.processed = 0;
      this.dvlaReview.cancelled = 0;
      this.debtCollectionReview.opened = 0;
      this.debtCollectionReview.processed = 0;
      this.debtCollectionReview.cancelled = 0;
      this.scannedDocuments.viewed = 0;
      this.scannedDocuments.processed = 0;
      this.scannedDocuments.restricted = 0;
      this.scannedDocuments.deleted = 0;

      this.getData();
    }
  }
}

</script>

<style scoped>
.spinner-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>