<template>

  <div style="width: 100%; margin-top: 20px;">
    <div class="row">
      <!-- TRAINING LOG -->
      <div class="col-4">

        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search for training...">
          <div class="input-group-append">
            <button class="btn btn-primary" type="button"><i class="fa fa-search"></i></button>
          </div>
        </div>

        <table class="table table-striped table-hover tx-white" style="margin-top: 20px;">
          <thead class="thead-colored thead-primary">
            <th>Date</th>
            <th>Topic(s)</th>
            <th></th>
          </thead>
          <tbody>
            <tr>
              <td>29/10/2024</td>
              <td>Fuel Appeals, Parking Appeals, POPLA</td>
              <td>
                <button class="btn btn-sm btn-primary">View Notes</button>
              </td>
            </tr>
            <tr>
              <td>27/10/2024</td>
              <td>Pending Claims, DVLA Review, Tampered Plates</td>
              <td>
                <button class="btn btn-sm btn-primary">View Notes</button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <!-- TRAINING NOTES -->
      <div class="col-8 tx-white">

        <div class="row">
          <div class="col">
            <h2 style="color: white; text-decoration: underline;">TRAINING NOTES - 29/10/2024</h2>
          </div>
        </div>

        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non porttitor augue, eget sodales sapien. Etiam mi nunc, semper vitae mollis ut, ultrices ac turpis. Phasellus scelerisque feugiat posuere. Vestibulum aliquam faucibus leo, eget gravida velit aliquam vel. Vivamus pharetra nulla ut odio commodo, nec molestie sapien laoreet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nisl nisi, laoreet nec turpis ac, interdum tempor risus. Integer porta sit amet felis at pellentesque. Vivamus egestas sit amet est et egestas. Aenean venenatis urna quis est hendrerit, in rhoncus enim pretium. Aenean in orci vitae lacus lobortis viverra. Praesent massa nulla, interdum eu metus in, dictum ultricies ipsum. Pellentesque ornare massa sed diam condimentum, sed vulputate magna dictum. Mauris ut magna nisi. Nunc eu commodo elit, ut facilisis odio.</p>

        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eu justo suscipit, hendrerit arcu id, porta nulla. In convallis felis quis metus venenatis mollis. Duis pharetra justo at elit blandit efficitur. Sed tincidunt, tellus sed consequat luctus, orci velit lacinia nulla, at vestibulum diam elit et dolor. Nullam vel dapibus nunc. Nullam semper, erat id venenatis fermentum, ex ex tristique justo, vel pulvinar eros purus ut enim. Pellentesque a tempor est. Donec a dui pretium dolor molestie cursus. Donec orci turpis, placerat ac consequat at, pretium sed massa.</p>

        <p>Sed a malesuada massa, quis eleifend ipsum. Pellentesque suscipit libero nec enim imperdiet feugiat. Suspendisse finibus, turpis eu finibus pellentesque, lorem lectus viverra erat, tincidunt venenatis justo ex id nibh. Maecenas sodales nisl ipsum, et pellentesque libero pellentesque eu. Aliquam vitae ligula sit amet quam venenatis tempor. Integer in congue orci. In ac massa eget felis vestibulum egestas. Sed condimentum a sem a faucibus. Nullam venenatis vitae lorem porttitor mollis. Praesent sem felis, hendrerit tincidunt venenatis ut, dignissim id tellus.</p>

        <p>Nullam maximus turpis ac purus maximus sagittis. Fusce ultrices efficitur quam vitae consequat. Aenean a purus sodales, scelerisque purus non, volutpat nunc. Donec sagittis, mi vitae pulvinar lobortis, dui justo ultricies tellus, at fermentum ipsum massa vitae metus. Etiam fringilla neque tortor, sit amet hendrerit risus elementum nec. Nullam vulputate nulla ut congue bibendum. Etiam vel nisl faucibus, molestie justo eget, scelerisque risus. Quisque vel augue at risus blandit consequat. In mollis tincidunt elit eget fermentum. Donec eu feugiat neque, nec placerat libero. Vestibulum rutrum molestie nisi ut vulputate. Quisque lacinia urna sit amet consequat aliquet. Etiam ante nisi, dictum vitae erat viverra, commodo auctor ante. Praesent et auctor tortor, a scelerisque eros. Nulla vehicula accumsan justo in egestas. Vivamus ornare molestie tellus, at mattis mi pharetra nec.</p>

        <p>Fusce ut tempor nunc. Donec vitae nulla tempus, suscipit mi id, varius lacus. Pellentesque tellus massa, mattis at mi et, malesuada posuere mi. Sed volutpat eget leo a ultricies. Nulla purus tellus, pretium non pretium vel, vehicula vel dui. Nulla aliquam eu enim in facilisis. Donec dui arcu, malesuada vitae justo id, ultrices interdum ante. Sed volutpat ante nec risus elementum fermentum. Nullam ultrices tempus justo, nec ultricies velit accumsan id. Donec blandit, lorem sit amet condimentum pharetra, tortor sem tincidunt lorem, ut elementum lorem eros quis tellus. Sed dictum convallis felis vitae fringilla. Aliquam luctus fringilla nulla, ut malesuada diam semper vitae. Integer vitae malesuada diam.</p>

        <p>Sed placerat purus risus, vitae tempor mi bibendum sed. Vestibulum maximus vehicula diam sit amet posuere. Sed tincidunt sed metus ut euismod. Mauris auctor scelerisque posuere. Morbi pharetra tempor mi, id volutpat massa tempor vitae. Pellentesque feugiat neque sapien, ut pharetra magna tempor non. Nulla facilisi. Vivamus volutpat felis vitae massa mollis, et porttitor ex pellentesque. Nulla porta at tortor eget imperdiet.</p>

      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Training',
  data(){
    return {

    }
  }
}

</script>