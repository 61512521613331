<template>
  <div style="width: 100%;">

    <div class="row">
      <!-- FUEL -->
      <div class="col-6">

        <div class="row row-sm mg-t-20">
          <div class="col">
              <div class="spinner-container">
                <h2 style="color: white; text-decoration: underline;">
                  FUEL
                  <div v-if="loading" class="spinner"></div>
                </h2>
              </div>
          </div>
        </div>

        <div class="row row-sm mg-t-20">

          <!-- VIEWED -->
          <div class="col-sm-6 col-lg-4">
            <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                    <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">CLAIMS VIEWED</h6>
                </div>
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                    <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{fuel.claimsViewed.toLocaleString()}}</h4>
                </div>
            </div>
          </div>

        </div>

        <div class="row row-sm mg-t-20">
          <!-- LOCKED -->
          <div class="col-sm-6 col-lg-4">
            <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                    <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">LOCKED</h6>
                </div>
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                    <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{fuel.locked.toLocaleString()}}</h4>
                </div>
            </div>
          </div>

          <!-- CANCELLED -->
          <div class="col-sm-6 col-lg-4">
            <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                    <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">CANCELLED</h6>
                </div>
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                    <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{fuel.cancelled.toLocaleString()}}</h4>
                </div>
            </div>
          </div>
        </div>

      </div>
      <!-- PARKING -->
      <div class="col-6">

        <div class="row row-sm mg-t-20">
          <div class="col-6">
              <div class="spinner-container">
                <h2 style="color: white; text-decoration: underline;">
                  PARKING
                  <div v-if="loading" class="spinner"></div>
                </h2>
              </div>
          </div>
          <div class="col-6">
            <h2>
              <input v-if="$can('claims-manage-team')" type="date" class="form-control-dark form-control" v-model="date" @change="getData" style="float: right; width: 150px;">
            </h2>
          </div>
        </div>

        <div class="row row-sm mg-t-20">

          <!-- VIEWED -->
          <div class="col-sm-6 col-lg-4">
            <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                    <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">CLAIMS VIEWED</h6>
                </div>
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                    <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{parking.claimsViewed.toLocaleString()}}</h4>
                </div>
            </div>
          </div>

        </div>

        <div class="row row-sm mg-t-20">

          <!-- PROCESSED -->
          <div class="col-sm-6 col-lg-4">
            <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                    <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">PROCESSED</h6>
                </div>
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                    <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{parking.claimsProcessed.toLocaleString()}}</h4>
                </div>
            </div>
          </div>

          <!-- APPEALS ACCEPTED -->
          <div class="col-sm-6 col-lg-4">
            <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                    <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">APPEALS ACCEPTED</h6>
                </div>
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                    <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{parking.appealsAccepted.toLocaleString()}}</h4>
                </div>
            </div>
          </div>

          <!-- APPEALS REJECTED -->
          <div class="col-sm-6 col-lg-4">
            <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                    <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">APPEALS REJECTED</h6>
                </div>
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                    <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{parking.appealsRejected.toLocaleString()}}</h4>
                </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <div class="row row-sm mg-t-20">
          <div class="col-12 text-center">
              <div class="spinner-container">
                <h2 style="color: white; text-decoration: underline;">
                  TICKETS RESOLVED ({{ticketsResolved}})
                  <div v-if="loading" class="spinner"></div>
                </h2>
              </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import { DateTime } from 'luxon';

export default {
  name: 'Appeals',
  props: {
    user_id: {
      type: Number,
      required: false
    }
  },
  data(){
    return {
      fuel: {
        claimsViewed: 0,
        locked: 0,
        cancelled: 0
      },
      parking: {
        claimsViewed: 0,
        claimsProcessed: 0,
        appealsAccepted: 0,
        appealsRejected: 0
      },
      ticketsResolved: 0,
      counter: 0,
      date: DateTime.local().toFormat('yyyy-MM-dd'),
      loading: false
    }
  },
  mounted(){
    this.getData();
  },
  methods: {
    getData(){ 
      this.loading = true;
      this.counter++;
      let currentCount = this.counter;
      axios.get(`https://api.varsanpr.com/api/dashboard/claims/appeals`, {
        headers: authHeader(),
        params: {
          user_id: this.user_id || null,
          date: this.date
        }
      })
      .then(response => {
        if(currentCount !== this.counter) return;
        this.fuel.claimsViewed = response.data.stats.fuelClaimsViewed;
        this.fuel.locked = response.data.stats.locked;
        this.fuel.cancelled = response.data.stats.cancelled;
        this.parking.claimsViewed = response.data.stats.parkingClaimsViewed;
        this.parking.claimsProcessed = response.data.stats.processed;
        this.parking.appealsAccepted = response.data.stats.appealsAccepted;
        this.parking.appealsRejected = response.data.stats.appealsRejected;
        this.ticketsResolved = response.data.stats.ticketsResolved || 0;
      })
      .catch(e => {
        console.log(e);
        this.$error('Failed to load claims stats');
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    user_id(){
      this.fuel.cancelled = 0;
      this.fuel.claimsViewed = 0;
      this.fuel.locked = 0;
      this.parking.appealsAccepted = 0;
      this.parking.appealsRejected = 0;
      this.parking.claimsProcessed = 0;
      this.parking.claimsViewed = 0;
      this.ticketsResolved = 0;
      this.getData();
    }
  }
}

</script>

<style scoped>
.spinner-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>